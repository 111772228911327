<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>CREAR PROCESO PRIMARIO</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label>Titulo</label>
            <input type="text" class="form-control" v-model="primary_process.title">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label>Descripcion</label>
            <textarea type="text" class="form-control" v-model="primary_process.description" rows="5"></textarea>
            <button class="btn bg-gradient-success w-100 mt-4" @click="createPrimaryProcess">CREAR PROCESO PRIMARIO</button>
          </div>
        </div>
    </div>
  </div>

</template>
<script>
import Swal from "sweetalert2";
import {post} from "@/utils/httpRequest";

export default {
  name: 'CreateProcessPrimary',
  data: () => ({
    primary_process: {
      title: '',
      description: '',
    }
  }),
  methods: {
    async createPrimaryProcess() {
      if(this.primary_process.title !== '' && this.primary_process.description !== ''){
        await post('main-process/create',this.primary_process)
        this.primary_process = {
          title: '',
          description: '',
        }
        await Swal.fire('Informacion creada correctamente', '', 'success')
      }else{
        await Swal.fire('Todos los campos son requeridos', '', 'error')
      }
    }
  }
}

</script>
<style scoped>

</style>
